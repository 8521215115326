<template>
    <div class="page1">

        <Button @click="goToDetail" type="primary">点我去详情</Button>

    </div>
</template>

<script>
  import {Button} from 'element-ui'
  export default {
    name: 'Login',
    data() {
      return {
        columnDefs: null,
        rowData: null
      }
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    components:{Button},
    beforeMount() {
      this.columnDefs = [
        {headerName: 'Make', field: 'make'},
        {headerName: 'Model', field: 'model'},
        {headerName: 'Price', field: 'price'}
      ];

      this.rowData = [
        {make: 'Toyota', model: 'Celica', price: 35000},
        {make: 'Ford', model: 'Mondeo', price: 32000},
        {make: 'Porsche', model: 'Boxter', price: 72000}
      ];
    },
    methods: {
      goToDetail(){
        this.$router.push({
          name:'Page3'
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
    .login-container {
        min-height: 100%;
        width: 100%;
        background-color: #fff;
        overflow: hidden;

        .login-form {
            position: relative;
            width: 520px;
            max-width: 100%;
            padding: 160px 35px 0;
            margin: 0 auto;
            overflow: hidden;
            box-sizing: border-box;
        }

        .tips {
            font-size: 14px;
            color: #fff;
            margin-bottom: 10px;

            span {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        .svg-container {
            padding: 6px 5px 6px 15px;
            color: #999;
            vertical-align: middle;
            width: 30px;
            display: inline-block;
        }

        .title-container {
            position: relative;

            .title {
                font-size: 26px;
                color:#fff;
                margin: 0px auto 40px auto;
                text-align: center;
                font-weight: bold;
            }
        }

        .show-pwd {
            position: absolute;
            right: 10px;
            top: 7px;
            font-size: 16px;
            color: #2d3a4b;
            cursor: pointer;
            user-select: none;
        }
    }
</style>
